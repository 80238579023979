<template>
  <div ref="modalArea" :class="{'modal-area': true, 'active': leaveSiteOpen}">
    <div ref="modalInner" class="modal">
      <div class="close-modal">
        <i class="bx bx-x" @click="closeModal"></i>
      </div>
      <div class="modal-content">
        <h4 class="modal-header leave-header">
          {{ $t('modals.you_are_leaving') }}
        </h4>
        <div class="content-area mb-4">
          <p class="gems mb-5">
            {{ $t('modals.you_are_leaving_desc1') }}
            <span class="bolder">{{ $t('modals.you_are_leaving_desc2') }}</span>.
            {{ $t('modals.you_are_leaving_desc3') }}
          </p>
        </div>
        <div class="button-area">
          <button type="button" class="button" @click="closeModal">
            {{ $t('modals.stay') }}
          </button>
          <button type="button" class="apply-button" @click="goToUrl">
            {{ $t('modals.leave') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ModalLeaveSiteComponent',
  props: {
    url: {
      type: String,
      default: () => {
        return this.$config.appUrl
      }
    }
  },
  data() {
    return {
      openExternal: false
    }
  },
  computed: {
    ...mapGetters('modal', [
      'leaveSiteOpen'
    ])
  },
  mounted() {
    window.addEventListener('click', this.handleClickEvents)
  },
  beforeDestroy() {
    window.removeEventListener('click', this.handleClickEvents)
  },
  methods: {
    ...mapActions('modal', [
      'changeLeaveSiteState'
    ]),
    goToUrl() {
      const openLocation = this.openExternal ? '_blank' : '_self'
      window.open(this.url, openLocation)
      this.closeModal()
    },
    handleClickEvents(evt) {
      if (
        this.$refs.modalArea !== undefined &&
          this.$refs.modalArea.contains(evt.target) &&
          !this.$refs.modalInner.contains(evt.target)
      ) {
        this.closeModal()
      }
    },
    closeModal() {
      this.changeLeaveSiteState({
        state: false
      })
    }
  }
}
</script>

<style scoped>
  @import url('~/assets/css/components/modal.css');
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "modalArea",
      class: { "modal-area": true, active: _vm.leaveSiteOpen },
    },
    [
      _c("div", { ref: "modalInner", staticClass: "modal" }, [
        _c("div", { staticClass: "close-modal" }, [
          _c("i", { staticClass: "bx bx-x", on: { click: _vm.closeModal } }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "modal-content" }, [
          _c("h4", { staticClass: "modal-header leave-header" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("modals.you_are_leaving")) +
                "\n      "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "content-area mb-4" }, [
            _c("p", { staticClass: "gems mb-5" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("modals.you_are_leaving_desc1")) +
                  "\n          "
              ),
              _c("span", { staticClass: "bolder" }, [
                _vm._v(_vm._s(_vm.$t("modals.you_are_leaving_desc2"))),
              ]),
              _vm._v(
                ".\n          " +
                  _vm._s(_vm.$t("modals.you_are_leaving_desc3")) +
                  "\n        "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "button-area" }, [
            _c(
              "button",
              {
                staticClass: "button",
                attrs: { type: "button" },
                on: { click: _vm.closeModal },
              },
              [
                _vm._v(
                  "\n          " + _vm._s(_vm.$t("modals.stay")) + "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "apply-button",
                attrs: { type: "button" },
                on: { click: _vm.goToUrl },
              },
              [
                _vm._v(
                  "\n          " + _vm._s(_vm.$t("modals.leave")) + "\n        "
                ),
              ]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }